.app__works {
  flex: 1;
  width: 100%;
  flex-direction: column;
}

.app__work-filter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;

  margin: 6.4rem 0 3.2rem;

  .app__work-filter-item {
    padding: 0.8rem 1.6rem;
    border-radius: 0.8rem;
    background-color: #fff;
    color: #000;
    font-weight: 800;
    cursor: pointer;
    transition: all 0.3s ease;
    margin: 0.8rem;

    &:hover {
      background-color: var(--secondary-color);
      color: #fff;
    }

    @media screen and (min-width: 2000px) {
      padding: 1.6rem 3.2rem;
      border-radius: 1.4rem;
    }
  }

  .item-active {
    background-color: var(--secondary-color);
    color: #fff;
  }
}

.app__work-portfolio {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  .app__work-item {
    width: 270px;
    flex-direction: column;

    margin: 3.2rem;
    padding: 1.6rem;

    border-radius: 0.8rem;
    background-color: #fff;
    color: #000;

    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      box-shadow: 0 0 25px #00000033;
    }
    
    @media screen and (min-width: 2000px) {
      width: 470px;
      padding: 2rem;
      border-radius: 1.2rem;
    }

    @media screen and (max-width: 300px) {
      width: 100%;
      padding: 1.6rem;
    }

  }
}

.app__work-img {
  width: 100%;
  height: 230px;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    border-radius: 0.8rem;
    object-fit: cover;
  }

  @media screen and (min-width: 2000px) {
    height: 350px;
  }
}

.app__work-hover{
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #00000080;
  border-radius: 0.8rem;
  opacity: 0;
  transition: all 0.3s ease;
  
  div{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #00000080;
    color: #fff;
    margin: 1.6rem;
    font-family: --var(--font-base);
    font-weight: 800;
    cursor: pointer;
    transition: all 0.3s ease;
    
    svg{
      width: 50%;
      height: 50%;
      color: var(--white-color);
    }
  }
}

.app__work-content{
  padding: 0.8rem;
  width: 100%;
  position: relative;
  flex-direction: column;

  h4{
    margin-top: 1.6rem;
    line-height: 1.5;
  }

  .app__work-tag{
    position: absolute;
    padding: 0.8rem 1.6rem;
    border-radius: 10px;
    background-color: #fff;
    top: -25px;
  }
}
